<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Sms Template/List</h4>

            </div>
          
            <div class="users-list-table">
                <div class="users-list-filter px-1 my-2 py-2 border rounded">
                    <div>
                        <div class="row align-items-center ">
                            
                            <div class="col-12 col-md-4 ">
                                <div class="form-group">
                                    <label>Template Type</label>
                                    <VueMultiselect v-model="selectedSmsTemplate" class="" placeholder="select option" :options="smsTempalteTypeOptions" :allow-empty="false" :close-on-select="true" label="name" track-by="value" :show-labels="false" />
                                </div>
                            </div>

                            
                            <div class="col-12 col-md-4 ">
                                <div class="form-group">
                                    <label>Template Name</label>
                                    <input v-model="getSmsTemplatesParamObj.name" class="form-control " type="text" placeholder="Enter Reference">
                                </div>
                            </div>

                            <div class="col-12 col-md-2  ">
                                <div class="form-group">
                                    <label style="visibility: hidden">Filter</label>
                                    <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0" @click="applySmsTemplateFilter(null)">Filter
                                    </button>
                                </div>
                            </div>
                          
                        </div>
                        
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table id="users-list-datatable" class="table">
                                <thead>
                                    <tr>
                                        <th class="position-relative" :style="{ width: '50%' }">NAME</th>

                                        <th class="position-relative " :style="{ width: '50%' }">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(smsTemplate, index) in smsTemplates" :key="index">
                                        <td class="">
                                            <span class="font-weight-bold">{{smsTemplate.name}}</span>
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <span type="button" class="px-0 py-0 view-edit-delete-icon btn" data-toggle="dropdown" :style="{height:'18px'}" aria-haspopup="true" aria-expanded="false">
                                                    <i class="bx bx-dots-vertical-rounded h-100"></i>
                                                </span>
                                                <div class="dropdown-menu">

                                                    <button class="dropdown-item" @click="viewExampleSmsTemplateHandler(smsTemplate.id)">
                                                        View Example template
                                                    </button>

                                                    <router-link :to="{name:'smsTemplateEdit',params:{id:smsTemplate.id}}" class="dropdown-item" >
                                                        Update 
                                                    </router-link>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ListPagination position="left" :pagination-data="paginateLinks" @getClickedPage="applySmsTemplateFilter" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="" data-toggle="modal" data-target="#exampleEmailTemplate"></div>
            <div class="" data-toggle="modal" data-target="#exampleSmsTemplate"></div>
            <ExampleEmailTemplate :exampleEmailTemplate="currentSmsTemplate?.example_view ?? ''" />
            <ExampleSmsTemplate :exampleSmsTemplate="currentSmsTemplate?.example_view ?? ''" />
          

        </section>
    </template>
</AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout";
import ListPagination from "@/components/backEnd/pagination/ListPagination";

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
// core packages
import {
    mapActions,
    mapGetters
} from "vuex";
import ExampleEmailTemplate from "@/views/backEnd/settings/notification/includes/ExampleEmailTemplate";
import ExampleSmsTemplate from "@/views/backEnd/settings/notification/includes/ExampleSmsTemplate";


export default {
    name: "EmailTemplateList",
    mixins: [ShowToastMessage, Loader, Authorization],
    components: {
        ListPagination,
        AppLayout,
        ExampleEmailTemplate,
        ExampleSmsTemplate

    },

    data() {
        return {
            selectedSmsTemplate: {
                    name: "None",
                    value: null
                },
            getSmsTemplatesParamObj: {
                name:'',
                order_by_name: 'ASC',
                paginate: 1,
                pagination: 10,
                page: "",
            },
            currentSmsTemplate: {},
            smsTempalteTypeOptions: [
                // 0:Auth, 1:Appointment, 2:Agreement, 3:Contact, 4:Order, 5:Payment, 6:Refund, 7:Other	
                {
                    name: "None",
                    value: null
                },
                {
                    name: "Auth",
                    value: 0
                },
                {
                    name: "Appointment",
                    value: 1
                },
                {
                    name: "Agreement",
                    value: 2
                },
                {
                    name: "Contact",
                    value: 3
                },
                {
                    name: "Order",
                    value: 4
                },
                {
                    name: "Payment",
                    value: 5
                },
                {
                    name: "Refund",
                    value: 6
                },
               
                {
                    name: "Other",
                    value: 7
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            smsTemplates: "appSmsTemplates/smsTemplates",
            paginateLinks: "appSmsTemplates/paginateLinks",

        }),

    },
    watch: {

    },
    methods: {
        ...mapActions({
            getSmsTemplates: "appSmsTemplates/getSmsTemplates",
        }),

        async applySmsTemplateFilter(pageNumber) {

            this.getSmsTemplatesParamObj.page = pageNumber;
            this.getSmsTemplatesParamObj.type = this.selectedSmsTemplate?.value ?? "";
            await this.getSmsTemplateList();
        },

      
        async getSmsTemplateList() {
            this.loader(true);
            const response = await this.getSmsTemplates(this.getSmsTemplatesParamObj);
            this.loader(false);
            if (response ?.message) {
                this.showToastMessage(response);
            }
        },
    
   
       
        viewExampleSmsTemplateHandler(templateId) {
            this.loader(true);
            this.currentSmsTemplate = {};
            const smsTemplateObj = this.smsTemplates.find((smsTemplate) => templateId == smsTemplate.id);
            if (smsTemplateObj !== undefined) {
                this.currentSmsTemplate = smsTemplateObj;

                this.loader(false);
                document.querySelector('[data-target="#exampleSmsTemplate"]').click();
            } else {
                this.loader(false);
                this.showToastMessage({
                    type: 'error',
                    message: 'something went wrong'
                });
            }
        },

    },

    async mounted() {
        await this.getSmsTemplateList();
    },
};
</script>

<style scoped>

</style>
